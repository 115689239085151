import React, { useState, useCallback, useEffect } from 'react';
import ReactGA from 'react-ga';
import Axios from 'axios';
import Header from './components/Header';
import Description from './components/Description';
import Rate from './components/Rate';
import Platforms from './components/Platforms';
import General from './components/General';
import ContactForm from './components/ContactForm';
import Footer from './components/Footer';
import Layout from './components/Layout';
import Details from './components/Details';
import Estimate from './components/Estimate';
import config from './config/schema';
import { calculate, extract } from './calculator';

function App() {
  const [state, setState] = useState(JSON.parse(JSON.stringify(config)));
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (window.location) {
      ReactGA.initialize('UA-175681468-1');
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  }, []);

  const sendMessage = useCallback(
    contactData => {
      try {
        ReactGA.event({
          category: 'Send',
          action: 'mail'
        });
        Axios.post('/api/contact', { contactData, features: extract(state) })
          .then(() => setState(state => ({ ...state, formSuccess: true })))
          .catch(() => setState(state => ({ ...state, formError: true })));
      } catch (error) {
        ReactGA.exception({
          description: error,
          fatal: true
        });
        setState(state => ({ ...state, formError: true }));
      }
    },
    [state]
  );

  const clearForm = useCallback(() => {
    setState(s =>
      s.formSuccess === true ? JSON.parse(JSON.stringify(config)) : calculate(s)
    );
  }, []);

  const handlePlatformSelect = useCallback((index, selected) => {
    setState(s => {
      if (index === 3) {
        s.platforms[1].selected = false;
        s.platforms[2].selected = false;
      }
      if (index === 1 || index === 2) {
        s.platforms[3].selected = false;
      }
      s.platforms[index].selected = selected;
      ReactGA.event({
        category: s.platformsTitle,
        action: s.platforms[index].title,
        label: `${s.platforms[index].selected}`
      });
      return calculate(s);
    });
  }, []);

  const handleSingleSelect = useCallback((index, subindex, selected) => {
    setState(s => {
      for (let j = 0; j < s.general[index].variants.length; j++) {
        s.general[index].variants[j].selected =
          j === subindex ? selected : false;
        ReactGA.event({
          category: s.general[index].title,
          action: s.general[index].variants[j].title,
          label: `${s.general[index].variants[j].selected}`
        });
      }
      return calculate(s);
    });
  }, []);

  const handleMultiSelect = useCallback((index, subindex, selected, type) => {
    setState(s => {
      s[type][index].variants[subindex].selected = selected;
      ReactGA.event({
        category: s[type][index].title,
        action: s[type][index].variants[subindex].title,
        label: `${selected}`
      });
      return calculate(s);
    });
  }, []);

  const handleChangeRate = useCallback(rate => {
    setState(s => {
      s.rate = rate;
      ReactGA.event({
        category: 'Rate',
        action: 'Change',
        label: `${rate}`
      });
      return calculate(s);
    });
  }, []);

  const handleOpenModal = action => {
    setIsModalOpen(action);
  };

  return (
    <>
      <Header />
      <Description />
      <Rate onChange={handleChangeRate} rate={state.rate} />
      <Platforms
        title={state.platformsTitle}
        platforms={state.platforms}
        onChange={handlePlatformSelect}
      />
      {state.platforms.some(p => p.selected) && (
        <>
          <Layout
            sidebar={
              <Estimate state={state} handleOpenModal={handleOpenModal} />
            }
          >
            <General general={state.general} onChange={handleSingleSelect} />
            <Details
              platforms={state.platforms}
              details={state.options}
              startIndex={state.general.length + 2}
              onChange={handleMultiSelect}
              type="options"
            />
            <Details
              platforms={state.platforms}
              details={state.specific}
              startIndex={state.general.length + state.options.length + 2}
              onChange={handleMultiSelect}
              type="specific"
            />
          </Layout>
          <ContactForm
            isModalOpen={isModalOpen}
            handleOpenModal={handleOpenModal}
            sendMessage={sendMessage}
            formSuccess={state.formSuccess}
            formError={state.formError}
            clearForm={clearForm}
          />
        </>
      )}
      <Footer />
    </>
  );
}

export default App;
