import React from 'react';
import PlatformCard from '../PlatformCard';
import BlockTitle from '../BlockTitle';
import './Platforms.scss';

const Platforms = ({ title, platforms, onChange }) => (
  <div className="platforms-container">
    <BlockTitle index={2} title={title} />
    <div className="platforms">
      {platforms.map((v, i) => (
        <PlatformCard {...v} key={i} index={i} onChange={onChange} />
      ))}
    </div>
  </div>
);

export default Platforms;
