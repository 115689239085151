import React from 'react';
import Image from '../Image';
import './Description.scss';

const Description = () => (
  <div className="description">
    <div className="block">
      <div className="tag-wrapper">
        <p className="font--12">Estimator</p>
      </div>
      <h1 className="font--h2-big">Calculate your app</h1>
      <p className="font--16">
        Geniusee estimator will help you to calculate the expected development
        time of your application as well as budget based on our experience
        working with software products. Select the items below which best
        describes your app and the features you require.
      </p>
    </div>
    <div className="image">
      <Image src="/images/estimation" />
    </div>
  </div>
);

export default Description;
