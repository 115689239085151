import React from 'react';
import './BlockTitle.scss';

const BlockTitle = ({ title, index }) => (
  <div className="block-title">
    <p className="font--h0">{index < 10 ? `0${index}` : index}</p>
    <h6 className="font--h3">{title}</h6>
  </div>
);

export default BlockTitle;
