import React, { useCallback } from 'react';
import Select from '../Select';
import Image from '../Image';
import './PlatformCard.scss';

const PlatformCard = ({ selected, title, icon, note, onChange, index }) => {
  const handleSingleSelectClick = useCallback(() => {
    onChange(index, !selected);
  }, [index, onChange, selected]);
  return (
    <div
      className={`platform-card${selected ? ' selected' : ''}`}
      onClick={handleSingleSelectClick}
    >
      <Image src={`/images/${icon}`} className="icon" />
      <Select selected={selected} platform />
      <h3 className="title">{title}</h3>
      <p className="note">{note}</p>
      {selected && <span className="arrow" />}
    </div>
  );
};

export default PlatformCard;
