import React, { useCallback } from 'react';
import BlockTitle from '../BlockTitle';
import Image from '../Image';
import './Rate.scss';
const regexp = new RegExp('^[0-9]');

const roundRate = (value, operand) => {
  if (value % 10 > 0) {
    return operand === 'increment'
      ? Math.ceil(value / 10) * 10
      : Math.floor(value / 10) * 10;
  }

  if (operand === 'increment') return value + 10;
  if (operand === 'decrement') return value - 10;
};

const Rate = ({ onChange, rate }) => {
  const handleChange = useCallback(
    value => {
      let newRate = value;
      try {
        newRate = Number(newRate);
        if (!regexp.test(`${newRate}`) || newRate < 0) {
          newRate = 0;
        }
        if (newRate > 1000) {
          newRate = 1000;
        }
        onChange(newRate);
      } catch (e) {}
    },
    [onChange]
  );

  return (
    <div className="rate-container">
      <BlockTitle
        index={1}
        title="What is your current or expected average hourly rate in USD per one team member ?"
      />
      <div className="rate">
        <label htmlFor="rate">
          Define average hourly rate of your development team. It can be average
          hourly cost of your inhouse team member or rates that you are paying
          to your technology partner.
        </label>
        <div className="input-container">
          <div
            className={`input-change ${rate <= 30 && 'disabled'}`}
            onClick={() => handleChange(roundRate(rate, 'decrement'))}
          >
            -
          </div>
          <div className="input-wrapper">
            <input
              id="rate"
              onChange={e => handleChange(e.target.value)}
              onBlur={e =>
                e.target.value < 30
                  ? handleChange(30)
                  : handleChange(e.target.value)
              }
              type="text"
              min="0.00"
              max="1000.00"
              step="10.00"
              value={`${rate}`}
            />
            <span>$/hour</span>
          </div>
          <div
            className="input-change"
            onClick={() => handleChange(roundRate(rate, 'increment'))}
          >
            +
          </div>
        </div>
      </div>
      <Image src="/images/shadow-coin" />
    </div>
  );
};

export default Rate;
