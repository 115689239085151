import React from 'react';

const Image = ({ src, hidden, className }) => (
  <picture
    className={
      'img' + (hidden ? ' unvisible' : '') + (className ? ' ' + className : '')
    }
  >
    <source type="image/svg" srcSet={`${src}.svg`} />
    <source type="image/webp" srcSet={`${src}.webp`} />
    <img src={`${src}.png`} alt="Geniusee" />
  </picture>
);

export default Image;
