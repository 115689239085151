import React from 'react';
import './Footer.scss';

const Footer = () => (
  <footer>
    <p className="copyrights">
      © {new Date().getFullYear()} Geniusee and/or its affiliates. All rights
      reserved.
    </p>
  </footer>
);

export default Footer;
